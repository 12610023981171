import React, { useState } from 'react';
import textureImage from './images/blackCementTexture.jpg';
import bandBlackAndWhite from './images/bandBlackAndWhite.jpg';
import sadith2 from './images/sadith2.jpg';
import Footer from './Footer';

function BookUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [buttonText, setButtonText] = useState("Submit");
  const [formMessage, setFormMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    
    // let response = await fetch("http://localhost:3001/bookus", {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/bookus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formData),
    });
    setButtonText("Submit");
    
    if(response.ok) { // Check if the response status is OK (200-299)
      let result = await response.json();
      setFormMessage("Thanks for contacting us! You will hear from us soon!"); // Success message
      // Optionally clear the form
      setFormData({name: '', email: '', message: ''});
    } else {
      setFormMessage("Error sending message. Please try again later."); // Error message
    }
  };


  return (
    <div className="flex flex-col p-4 pt-0 md:pt-4 text-white">

      <div className="py-12 flex flex-col items-center justify-around max-w-7xl mx-auto space-y-8 lg:flex-row lg:space-y-0 lg:space-x-12 xl:space-x-20">

        <div className="md:order-first order-2 flex-1 flex justify-center pt-4 md:pt-4">
          <img src={sadith2} alt="Sadith" className="rounded-lg shadow-lg w-full h-auto max-w-xl lg:max-w-2xl" />
        </div>

        <div className="md:order-2 order-first w-full lg:w-2/5 xl:w-2/5 2xl:w-1/3 bg-white bg-opacity-25 rounded-lg shadow-xl p-8 lg:p-12 xl:p-16">
          <h1 className="text-3xl md:text-4xl font-bold mb-6 text-center">Want to book us?</h1>
          <form id="contactForm" onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white text-lg font-bold mb-2">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div>
              <label className="block text-white text-lg font-bold mb-2">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div>
              <label className="block text-white text-lg font-bold mb-2">Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <button
              type="submit"
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline"
            >{buttonText}
            </button>
          </form>
          <div id="formMessage">{formMessage}</div>
        </div>

        <div className="md:order-3 order-3 flex-1 flex justify-center pt-4 md:pt-4">
          <img src={bandBlackAndWhite} alt="Band" className="rounded-lg shadow-lg w-full h-auto max-w-xl xl:max-w-2xl" />
        </div>
      </div>
      <div className="md:mt-24">
        <Footer />
      </div>
    </div>
  );
};

export default BookUs;


