import React, { useState } from 'react';
import ShowsAdmin from './ShowsAdmin';
import MusicAdmin from './MusicAdmin';
import Footer from './Footer';
// import VideosAdmin from './VideosAdmin';

function Admin() {
  const [activeSection, setActiveSection] = useState('shows');

  const buttonStyle = {
    background: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    margin: '0 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    background: '#0056b3',
  };


  return (
    <div className="admin-container">
      <nav className="admin-navigation text-white" style={{ textAlign: 'center', margin: '20px 0' }}>
        <button
          onClick={() => setActiveSection('shows')}
          style={activeSection === 'shows' ? activeButtonStyle : buttonStyle}
        >
          Shows
        </button>
        <button
          onClick={() => setActiveSection('music')}
          style={activeSection === 'shows' ? activeButtonStyle : buttonStyle}
        >
          Music
        </button>
        {/* <button
          onClick={() => setActiveSection('videos')}
          style={activeSection === 'shows' ? activeButtonStyle : buttonStyle}
        >
          Videos
        </button> */}
      </nav>

      <div className="admin-content">
        {activeSection === 'shows' && <ShowsAdmin />}
        {activeSection === 'music' && <MusicAdmin />}
        {/* {activeSection === 'videos' && <VideosAdmin />} */}
      </div>
      <Footer />
    </div>
  );
}

export default Admin;