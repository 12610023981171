// import React from 'react';
// import textureImage from './images/blackCementTexture.jpg';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

// // function Footer() {
// //   return (
// //     <footer className="w-3/4 mx-auto flex justify-center items-center text-white border-t-4 pb-6">
// //       <div className="flex justify-center items-center mt-4 md:hidden">
// //         <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2">
// //           <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" style={{ fill: 'white' }} />
// //         </a>
// //         <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2">
// //           <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" style={{ fill: 'white' }} />
// //         </a>
// //         {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2">
// //         <TwitterLogo className="h-6 w-6" style={{ fill: 'white' }}/>
// //       </a>
// //       <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2">
// //         <InstagramLogo className="h-6 w-6" style={{ fill: 'white' }}/>
// //       </a> */}
// //       </div>

// //       {/* Lock icon and copyright text stacked and aligned to the left on mobile, centered on desktop */}
// //       <div className="w-full md:flex md:justify-center md:items-center">
// //         <div className="flex flex-col items-start md:items-center md:flex-row">
// //           <a href="/admin" target="_blank" rel="noopener noreferrer" className="mb-2 md:mb-0 md:mr-2">
// //             <FontAwesomeIcon icon={faLock} className="h-6 w-6" />
// //           </a>
// //           <span className="text-xs md:text-base">
// //             &copy; Bam! 2024
// //           </span>
// //         </div>
// //       </div>

      
// //     </footer>
// //   );
// // }



// // export default Footer;

// // function Footer() {
// //   return (
// //     <footer className="text-white border-t-4 pb-6 w-full flex flex-col items-center">
// //       {/* Social media icons: Centered and visible only on mobile */}
// //       <div className="flex justify-center mt-4 mb-2 w-full md:hidden">
// //         <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2">
// //           <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
// //         </a>
// //         <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2">
// //           <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
// //         </a>
// //       </div>

// //       {/* Lock icon and copyright text */}
// //       {/* On mobile: aligned to the left but on the same horizontal line as the social media icons */}
// //       {/* On desktop: centered */}
// //       <div className="md:flex md:justify-center md:items-center w-full">
// //         <div className="flex flex-col items-start md:flex-row md:items-center justify-start md:justify-center px-4 md:px-0">
// //           <a href="/admin" target="_blank" rel="noopener noreferrer" className="md:mr-2">
// //             <FontAwesomeIcon icon={faLock} className="h-6 w-6 mb-2 md:mb-0" />
// //           </a>
// //           <span className="text-xs md:text-base">
// //             &copy; Bam! 2024
// //           </span>
// //         </div>
// //       </div>
// //     </footer>
// //   );
// // }

// // export default Footer;



// function Footer() {
//   return (
//     <footer className="w-3/4 mx-auto flex justify-center items-center text-white border-t-4 pb-6">
//       {/* Container for the entire footer content */}
//       <div className="flex flex-col justify-between md:items-center w-full mt-4 md:mt-4">
        
//         {/* Container for the lock icon and copyright */}
//         <div className="flex items-center md:justify-center px-4 md:px-0 mb-2 md:mb-0 md:hidden">
//           <a href="/admin" target="_blank" rel="noopener noreferrer" className="md:mr-2">
//             <FontAwesomeIcon icon={faLock} className="h-6 w-6" />
//           </a>
//           <span className="text-xs md:text-base ml-2">
//             &copy;Bam!2024
//           </span>
//         </div>

//         {/* Container for social media icons */}
//         <div className="flex justify-center w-full md:hidden">
//           <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2">
//             <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
//           </a>
//           <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2">
//             <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
//           </a>
//         </div>
//       </div>

//       {/* Desktop version: centered lock icon and copyright */}
//       <div className="hidden md:flex md:justify-center md:items-center w-full">
//         <a href="/admin" target="_blank" rel="noopener noreferrer">
//           <FontAwesomeIcon icon={faLock} className="h-6 w-6 mr-2" />
//         </a>
//         <span className="text-base">
//           &copy; Bam! 2024
//         </span>
//       </div>
//     </footer>
//   );
// }

// export default Footer;






// function Footer() {
//   return (
//     <footer className="w-full text-white border-t-4 pb-6 flex flex-col items-center">
//       {/* Social media icons: Visible only on mobile */}
//       <div className="flex justify-center mt-4 md:hidden">
//         <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2">
//           <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
//         </a>
//         <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2">
//           <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
//         </a>
//       </div>

//       {/* Lock icon and copyright text */}
//       <div className="w-full px-4 md:px-0 mt-2 md:mt-4 flex md:justify-center">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-center w-full md:w-auto">
//           <a href="/admin" target="_blank" rel="noopener noreferrer" className="md:mr-2">
//             <FontAwesomeIcon icon={faLock} className="h-6 w-6 md:text-center" />
//           </a>
//           <span className="text-xs md:text-base">
//             &copy; Bam! 2024
//           </span>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;



// function Footer() {
//   return (
//     <footer className="w-full mx-auto flex justify-center items-center text-white pb-6 flex-col md:flex-row md:w-3/4 md:border-t-4 md:border-white md:px-6">
//     {/* // <footer className="w-full mx-auto flex justify-center items-center text-white pb-6 px-6"> */}
//       {/* <div className="w-3/4 border-t-4 border-white mt-4 mb-4 md:mb-0"></div> */}



//       <div className="flex justify-center items-center mt-4 mb-4 md:mb-0 w-full">
//         {/* Social Media Icons: Visible on mobile, hidden on desktop */}
//         <div className="flex justify-center items-center w-full md:hidden">
//           <a href="https://www.facebook.com" className="mx-2" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
//           </a>
//           <a href="https://www.youtube.com" className="mx-2" target="_blank" rel="noopener noreferrer">
//             <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
//           </a>
//           {/* Add more social media icons as needed */}
//         </div>
//       </div>

//       {/* Admin Lock Icon and Copyright */}
//       <div className="flex flex-col md:flex-row justify-center items-center w-full pt-4">
//         <a href="/admin" className="mx-2" target="_blank" rel="noopener noreferrer">
//           <FontAwesomeIcon icon={faLock} className="h-6 w-6 text-red-500" />
//         </a>
//         <span className="text-xs md:text-base pt-2 md:pt-0">
//           <FontAwesomeIcon className="h-4 w-4" />
//           &copy; Bam! 2024
//         </span>
//       </div>
//     </footer>
//   );
// }

import React from 'react';
import textureImage from './images/blackCementTexture.jpg';
import AdminLink from './AdminLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    // <footer className="w-full mx-auto text-white border-t-4 pb-6">
      // <footer className="w-full mx-auto text-white pb-6">
      <footer className="w-full text-white pb-6 flex flex-col items-center">
        <div className="w-3/4 border-t-4 mx-auto"></div>
      {/* Mobile version: three sections for lock icon, copyright, and social media */}
      <div className="flex justify-between items-center md:hidden w-full px-16 mt-4">
        {/* Lock icon on the left */}
        {/* <a href="/admin" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLock} className="h-6 w-6" />
        </a> */}
        <AdminLink />

        {/* Copyright centered */}
        <span className="text-xs absolute left-1/2 transform -translate-x-1/2">
        &copy; {new Date().getFullYear()} Bam!
        </span>

        {/* Social media icons on the right */}
        <div>
          <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="ml-2">
            <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
          </a>
          <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="ml-2">
            <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
          </a>
        </div>
      </div>

      {/* Desktop version: everything centered */}
      <div className="hidden md:flex md:justify-center md:items-center w-full mt-4">
        {/* <a href="/admin" target="_blank" rel="noopener noreferrer" className="mr-2">
          <FontAwesomeIcon icon={faLock} className="h-6 w-6" />
        </a> */}
        <div className="mr-4">
        <AdminLink />
        </div>
        <span className="text-xl mr-4">
        &copy; {new Date().getFullYear()} Bam!
        </span>
        {/* <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faFacebook} className="h-6 w-6" />
        </a>
        <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faYoutube} className="h-6 w-6" />
        </a> */}
      </div>
    </footer>
  );
}

export default Footer;