import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Check if the auth token exists in localStorage
  const isAuthenticated = localStorage.getItem('authToken');
  // console.log('Is Authenticated:', isAuthenticated);

  // If not authenticated, redirect to the login page
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  // If authenticated, render the children components (protected content)
  return children;
};

export default ProtectedRoute;



