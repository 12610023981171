import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo1 from './images/logo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const { authToken, logout } = useAuth();
  // const navigate = useNavigate();


  // const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Ensuring to close the menu when a NavLink is clicked in the mobile version
  const handleNavLinkClick = () => isMenuOpen && setIsMenuOpen(false);

  // const handleLoginLogoutClick = () => {
  //   if (authToken) {
  //     logout();
  //     navigate('/login');
  //   } else {
  //     navigate('/login');
  //   }
  // };

  return (
    <div className="relative bg-cover bg-no-repeat py-4 px-4 text-white">

      {/* Logo */}
      <div className="text-center">
        <NavLink to="/">
          <img src={logo1} alt="Logo" className="h-24 sm:h-36 mx-auto" />
        </NavLink>
      </div>

      <div className="absolute right-4 top-4 sm:hidden">
        <NavLink to="/BookUs" className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg">
          Book Us
        </NavLink>
      </div>

      {/* Navigation Links */}
      <nav className="flex justify-between items-center">
        <div className="hidden sm:block">
          <NavLink to="/BookUs" onClick={handleNavLinkClick} className="text-3xl font-mono bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg">
            Book Us
          </NavLink>
        </div>

        {/* Central Navigation Links */}
        <div className="flex-grow">
          <ul className={`flex justify-center space-x-4 sm:space-x-12 sm:flex`}>
          
            
            <li>
              <NavLink
                to="/"
                onClick={handleNavLinkClick}
                className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/AboutUs"
                onClick={handleNavLinkClick}
                className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Videos"
                onClick={handleNavLinkClick}
                className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
              >Videos
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Shows"
                onClick={handleNavLinkClick}
                className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
              >Shows
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Music"
                onClick={handleNavLinkClick}
                className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
              >Music
              </NavLink>
            </li>
            
          </ul>
        </div>

        {/* Social Media Icons - Right Side */}
        <div className="hidden sm:flex">
          <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faFacebook} size="2xl" /></a>
          <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faYoutube} size="2xl" /></a>
          {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faTwitter} size="2xl" /></a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faInstagram} size="2xl" /></a> */}

          {/* Login/Logout Button */}
          {/* <button onClick={handleLoginLogoutClick} className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg ml-4">
            <FontAwesomeIcon icon={authToken ? faUnlock : faLock} size="lg" />
          </button> */}

        </div>
      </nav>

      {/* White Border Line at the bottom of the navbar */}
      <div className="border-b-4 border-white w-full mt-4"></div>

      {/* <div className="text-center mt-2 sm:hidden">
          <NavLink to="/BookUs" onClick={handleNavLinkClick} className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg text-sm sm:text-base">
            Book Us
          </NavLink>
        </div> */}

    </div>
  );
}

export default Navbar;





// import React, { useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import logo1 from './images/logo1.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
// import { useAuth } from '../context/AuthContext';

// function Navbar() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const { authToken, logout } = useAuth();
//   const navigate = useNavigate();


//   const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

//   // Ensuring to close the menu when a NavLink is clicked in the mobile version
//   const handleNavLinkClick = () => isMenuOpen && setIsMenuOpen(false);

//   const handleLoginLogoutClick = () => {
//     if (authToken) {
//       logout();
//       navigate('/login');
//     } else {
//       navigate('/login');
//     }
//   };

//   return (
//     <div className="relative bg-cover bg-no-repeat py-4 px-4 text-white">

//       {/* Logo */}
//       <div className="text-center">
//         <NavLink to="/">
//           <img src={logo1} alt="Logo" className="h-24 sm:h-36 mx-auto" />
//         </NavLink>
//       </div>

//       {/* <div className="absolute right-4 top-4 sm:hidden">
//         <NavLink to="/BookUs" className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg">
//           Book Us
//         </NavLink>
//       </div> */}

//       {/* Navigation Links */}
//       <nav className="flex justify-between items-center">
//         <div className="hidden sm:block">
//           <NavLink to="/BookUs" onClick={handleNavLinkClick} className="text-3xl font-mono bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg">
//             Book Us
//           </NavLink>
//         </div>

//         {/* Central Navigation Links */}
//         <div className="flex-grow">
//           <ul className={`flex justify-center space-x-2 sm:space-x-12`}>
//           <li className="sm:hidden">
//               <NavLink
//                 to="/BookUs"
//                 onClick={handleNavLinkClick}
//                 className="text-lg font-mono text-sm sm:text-3xl bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg"
//               >
//                 Book Us
//               </NavLink>
//             </li>
            
//             <li>
//               <NavLink
//                 to="/"
//                 onClick={handleNavLinkClick}
//                 className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
//               >
//                 Home
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/AboutUs"
//                 onClick={handleNavLinkClick}
//                 className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
//               >
//                 About Us
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/Videos"
//                 onClick={handleNavLinkClick}
//                 className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
//               >Videos
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/Shows"
//                 onClick={handleNavLinkClick}
//                 className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
//               >Shows
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/Music"
//                 onClick={handleNavLinkClick}
//                 className={({ isActive }) => isActive ? "text-lg font-mono text-sm sm:text-3xl text-yellow-500" : "text-lg font-mono text-sm sm:text-3xl text-white"}
//               >Music
//               </NavLink>
//             </li>
            
//           </ul>
//         </div>

//         {/* Social Media Icons - Right Side */}
//         <div className="hidden sm:flex">
//           <a href="https://www.facebook.com/people/BAM/61556696647678/" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faFacebook} size="2xl" /></a>
//           <a href="https://www.youtube.com/playlist?list=PL-cPsbkdWiYYPEBJQJ2cYKrQWicTrNog2" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faYoutube} size="2xl" /></a>
//           {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faTwitter} size="2xl" /></a>
//           <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2"><FontAwesomeIcon icon={faInstagram} size="2xl" /></a> */}

//           {/* Login/Logout Button */}
//           <button onClick={handleLoginLogoutClick} className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg ml-4">
//             <FontAwesomeIcon icon={authToken ? faUnlock : faLock} size="lg" />
//           </button>

//         </div>
//       </nav>

//       {/* White Border Line at the bottom of the navbar */}
//       <div className="border-b-4 border-white w-full mt-4"></div>

//       {/* <div className="text-center mt-2 sm:hidden">
//           <NavLink to="/BookUs" onClick={handleNavLinkClick} className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg text-sm sm:text-base">
//             Book Us
//           </NavLink>
//         </div> */}

//     </div>
//   );
// }

// export default Navbar;


