// import React, { createContext, useContext, useState, useEffect } from 'react';

// const AuthContext = createContext(null);

// export const AuthProvider = ({ children }) => {
//   const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));

//   useEffect(() => {
//     const storedToken = localStorage.getItem('authToken');
//     if (storedToken !== authToken) {
//       setAuthToken(storedToken);
//     }
//   }, [authToken]);

//   const login = (token) => {
//     localStorage.setItem('authToken', token); // Store token in local storage
//     setAuthToken(token); // Update context state
//   };

//   const logout = () => {
//     localStorage.removeItem('authToken'); // Remove token from local storage
//     setAuthToken(null); // Clear context state
//   };

//   return (
//     <AuthContext.Provider value={{ authToken, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        // Assuming you have a backend endpoint to verify the token
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/verify-token`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        // If the backend verifies the token successfully
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        setIsAuthenticated(false);
      }
    };

    if (authToken) {
      verifyToken();
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken]);

  const login = (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ authToken, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

