import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutUs from './components/AboutUs';
import Shows from "./components/Shows";
import Videos from "./components/Videos";
import Music from "./components/Music";
import BookUs from "./components/BookUs";
import Admin from "./components/Admin";
import LoginForm from "./components/LoginForm";
import ProtectedRoute from "./components/ProtectedRoute";
import textureImage from './components/images/blackCementTexture.jpg';



function App() {

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        backgroundImage: `url(${textureImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <AuthProvider>
        <Router>
          <Navbar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Shows" element={<Shows />} />
              <Route path="/Videos" element={<Videos />} />
              <Route path="/Music" element={<Music />} />
              <Route path="/BookUs" element={<BookUs />} />
              <Route path="/Login" element={<LoginForm />} />
              <Route 
              path="/Admin" 
              element={
              <ProtectedRoute>
              <Admin />
              </ProtectedRoute>
              } />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;