import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './Footer';

function ShowsAdmin() {
  const [shows, setShows] = useState([]);
  const [newShow, setNewShow] = useState({
    date: '',
    venue: '',
    city: '',
  });
  const [editingShow, setEditingShow] = useState(null);

  useEffect(() => {
    const fetchShows = async () => {
      try {
        // const response = await axios.get('http://localhost:3001/api/shows', {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shows`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        setShows(response.data.shows);
        console.log(shows)
        console.log(response.data.shows);
      } catch (error) {
        console.error("Failed to fetch shows:", error);
      }
    };

    fetchShows();
  }, []);


  useEffect(() => {
    console.log('Editing show ID has been updated:', editingShow);
  }, [editingShow]);

  const addShow = async (event) => {
    event.preventDefault();

    try {
      // const response = await axios.post('http://localhost:3001/api/shows', newShow);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/shows`, newShow);
      setShows([...shows, response.data.show]); // Add the new show to the current list
      setNewShow({ date: '', venue: '', city: '' }); // Reset the form
    } catch (error) {
      console.error("Failed to add show:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewShow((prevShow) => ({
      ...prevShow,
      [name]: value,
    }));
  };

  const deleteShow = async (showId) => {
    try {
      // await axios.delete(`http://localhost:3001/api/shows/${showId}`);
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/shows/${showId}`);
      setShows(shows.filter(show => show._id !== showId)); // Remove the show from the state
    } catch (error) {
      console.error("Failed to delete show:", error);
    }
  };

  const startEdit = (show) => {
    console.log('Selected show ID for editing:', show._id);
    setEditingShow(show._id); // Set the show to be edited
    // setting the form fields to the values of the show to be edited
    setNewShow({ date: show.date, venue: show.venue, city: show.city });
    console.log('Editing show ID after set:', editingShow);
  };

  const submitEdit = async (event) => {
    event.preventDefault();
    console.log('Editing show ID before submitting:', editingShow);

    if (!editingShow) {
      console.error('No editing show ID available');
      return;
    }

    try {
        // const response = await axios.put(`http://localhost:3001/api/shows/${editingShow}`, newShow);
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/shows/${editingShow}`, newShow);
        setShows(prevShows => prevShows.map(show => show._id === editingShow ? { ...response.data.show } : show));
        setEditingShow(null); // Clear the editing state
        setNewShow({ date: '', venue: '', city: '' }); // Reset the form
    } catch (error) {
        console.error("Failed to update show:", error);
    }
};

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="text-center text-xl text-white font-bold my-4">Admin Page</h1>

      <form onSubmit={editingShow ? submitEdit : addShow} className="mx-auto my-4">
        <div className="mb-4">
          <label htmlFor="date" className="block text-sm font-medium text-white">Date:</label>
          <input
            type="date"
            name="date"
            id="date"
            value={newShow.date}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="venue" className="block text-sm font-medium text-white">Venue:</label>
          <input
            type="text"
            name="venue"
            id="venue"
            value={newShow.venue}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="city" className="block text-sm font-medium text-white">City:</label>
          <input
            type="text"
            name="city"
            id="city"
            value={newShow.city}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Add Show</button>
      </form>

      <div className="shows-list mx-auto w-full max-w-4xl p-4">
        {Object.values(shows).map((show) => (
          <div key={show._id} className="show-item mb-2 flex justify-between items-center p-2 bg-white shadow rounded">
            <span>
              {show.date} at {show.venue}, {show.city}
            </span>
            <span>
              <button onClick={() => startEdit(show)} className="mx-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">Edit</button>
              <button onClick={() => deleteShow(show._id)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">Delete</button>
            </span>
          </div>
        ))}
      </div>  
      
    </div>
  );
}

export default ShowsAdmin;