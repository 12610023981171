import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './Footer';

function MusicAdmin() {
  const [music, setMusic] = useState([]);
  const [newMusic, setNewMusic] = useState({
    name: '',
    artist: '',
    location: '',
  });
  const [editingMusic, setEditingMusic] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        // const response = await axios.get('http://localhost:3001/api/music', {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/music`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        console.log('Fetched music items:', response.data.music);
        setMusic(response.data.music);
        console.log(music);
        console.log(response.data.music);
      } catch (error) {
        console.error("Failed to fetch music:", error);
      }
    };

    fetchMusic();
  }, []);


  useEffect(() => {
    console.log('Editing music ID has been updated:', editingMusic);
  }, [editingMusic]);

  const addMusic = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', newMusic.name);
    formData.append('artist', newMusic.artist);
    formData.append('location', newMusic.location);
    formData.append('mp3File', file);

    try {
      // const response = await axios.post('http://localhost:3001/api/music/upload', formData, {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/music/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data.music);
      console.log('response', response);
      console.log('calling setMusic');
      setMusic([...music, response.data.music]);
      console.log('calling setNewMusic');
      setNewMusic({ name: '', artist: '', location: '' });
      console.log('calling setFile');
      setFile(null);
    } catch (error) {
      console.error("Failed to add music:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewMusic((prevMusic) => ({
      ...prevMusic,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const deleteMusic = async (musicId) => {
    try {
      // await axios.delete(`http://localhost:3001/api/music/${musicId}`);
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/music/${musicId}`);
      setMusic(music.filter(music => music._id !== musicId));
    } catch (error) {
      console.error("Failed to delete music:", error);
    }
  };

  const startEdit = (music) => {
    console.log('startEdit function called for music ID:', music._id);
    setEditingMusic(music._id);
    setNewMusic({ name: music.name, artist: music.artist, location: music.location });
    console.log('Editing music ID after set:', editingMusic);
  };

  //SubmitEdit AFTER ADDING THE MP3
  const submitEdit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('name', newMusic.name);
    formData.append('artist', newMusic.artist);
    formData.append('location', newMusic.location);
    if (file) {
      formData.append('mp3File', file);
    }

    try {
      // const response = await axios.put(`http://localhost:3001/api/music/${editingMusic}`, formData, {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/music/${editingMusic}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMusic(prevMusic => prevMusic.map(music => music._id === editingMusic ? { ...response.data.music } : music));
      setEditingMusic(null);
      setNewMusic({ name: '', artist: '', location: '' });
      setFile(null);
    } catch (error) {
      console.error("Failed to update music:", error);
    }
  };

  const editingItem = music.find((m) => m._id === editingMusic);

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="text-center text-xl text-white font-bold my-4">Admin Page Music</h1>

      <form onSubmit={editingMusic ? submitEdit : addMusic} className="mx-auto my-4" encType="multipart/form-data">
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-white">Name</label>
          <input
            type="name"
            name="name"
            id="name"
            value={newMusic.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="artist" className="block text-sm font-medium text-white">Artist:</label>
          <input
            type="text"
            name="artist"
            id="artist"
            value={newMusic.artist}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="location" className="block text-sm font-medium text-white">Location:</label>
          <input
            type="text"
            name="location"
            id="location"
            value={newMusic.location}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="mp3" className="block text-sm font-medium text-white">Mp3:</label>
          <input
            type="file"
            name="mp3File"
            id="mp3File"
            onChange={handleFileChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          // required={!editingMusic && !file}
          />
          {editingMusic && editingItem && (
            <div className="text-sm text-white">
              Current file: {editingItem.mp3FilePath.split('/').pop()}
            </div>
          )}
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
          {editingMusic ? 'Update Music' : 'Add Music'}
        </button>
      </form>

      <div className="shows-list mx-auto w-full max-w-4xl p-4">
        {Object.values(music).map((music, key) => (

          <div key={key} className="music-item mb-2 flex justify-between items-center p-2 bg-white shadow rounded">
            <span>
              {music.name} by {music.artist} at {music.location}
            </span>
            <span>
              <button onClick={() => startEdit(music)} className="mx-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700">Edit</button>
              <button onClick={() => deleteMusic(music._id)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">Delete</button>
            </span>
          </div>

        ))}
      </div>


    </div>
  );
}

export default MusicAdmin;