import React, { useState, useRef } from "react";
import Footer from './Footer';
import Navbar from './Navbar';

function Videos() {
  const [mainVideoUrl, setMainVideoUrl] = useState("https://www.youtube.com/embed/9Q0-TCYmIHc");
  const mainVideoRef = useRef(null);

  const videos = [
    "https://www.youtube.com/embed/9Q0-TCYmIHc",
    "https://www.youtube.com/embed/3ZYAMnZnHdM",
    "https://www.youtube.com/embed/dtyLMJ6M_kk",
    "https://www.youtube.com/embed/QApVQXDQwrI"
  ];

  const handleThumbnailClick = (video) => {
    setMainVideoUrl(video);
    if (mainVideoRef.current) {
      mainVideoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col justify-center min-h-screen px-4 py-8">

      <main className="flex-grow w-full max-w-5xl mx-auto">
        {/* Main Video */}
        <div className="mb-8 relative" style={{paddingTop: "56.25%"}} ref={mainVideoRef}> 
          <iframe
            src={mainVideoUrl}
            title="Main YouTube video player"
            frameBorder="0"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full"
          ></iframe>
        </div>

        {/* Thumbnails */}
        <div className="mt-[4rem] grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-4 pb-10">
          {videos.map((video, index) => (
            <div key={index} className="aspect-video cursor-pointer overflow-hidden" onClick={() => handleThumbnailClick(video)}> {/* Added red border */}
              <img src={`https://img.youtube.com/vi/${video.split("/embed/")[1]}/0.jpg`} alt={`Video ${index + 1}`} className="w-full h-full object-cover"/>
            </div>
          ))}
        </div>
      </main>

      <div className="pt-8">
      <Footer />
      </div>
    </div>
  );
}

export default Videos;