import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, { username, password });
      login(response.data.token);
      navigate('/Admin');
    } catch (error) {
      console.error('Login error:', error.response);
      setError('Login failed. Please check your credentials.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mt-20 text-center text-white">
      <h1 className="text-3xl mb-5">Welcome Bam!</h1>
      <form onSubmit={handleSubmit} className="inline-block text-left">
        {error && <div className="text-red-500">{error}</div>}
        <div className="mb-4">
          <label className="block text-lg mb-2">
            Username:
            <input
              className="text-black text-lg p-2 mt-1 w-full"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
        </div>
        <div className="mb-4 relative">
          <label className="block text-lg mb-2">
            Password:
            <input
              className="text-black text-lg p-2 mt-1 w-full"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 top-2 lg">
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
          </label>
        </div>
        <button type="submit" className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded">
          Login
        </button>
      </form>

    </div>
  );
}

export default LoginForm;
