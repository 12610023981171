import bamCoverImage from './images/bamCoverImage.jpg';
import textureImage from './images/blackCementTexture.jpg';
import Footer from './Footer';

function Home() {
  return (
    <div
      className="flex flex-col min-h-screen"

    >
      <main className="flex-grow px-4 py-8 flex flex-col items-center justify-center space-y-8">

      <video 
        className="w-auto h-auto sm:h-[54vh] max-w-full object-cover"
        autoPlay 
        loop 
        muted 
        controls
      >
        <source src="/videos/promoVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="text-center text-white max-w-full px-4">
          <h2 className="text-4xl font-bold font-mono underline mb-4">Welcome to BAM!</h2>
          <p className="text-sm sm:text-2xl font-mono">Looking for a cover band sure to get people dancing?  Look no further than BAM!, from London, Ontario. We specialise in classic rock, with the odd modern throwback here and there. Reach out to us if you want an evening of The Beatles, Bryan Adams, Fleetwood Mac, The Rolling Stones, Dire Straits, Tragically Hip, and more!
            </p>
        </div>


        <img src={bamCoverImage} alt="Cover Image" className="w-auto h-auto sm:h-[70vh] max-w-full object-cover" />
 
      </main>
      <Footer />
      {/* <div className="md:hidden">
      <Footer />
      </div> */}
    </div>
  );
};

export default Home;