import React, { useState, useEffect } from 'react';
import axios from 'axios';
import tim3 from './images/tim3.jpg';
import Footer from './Footer';


function Music() {

  const [musicList, setMusicList] = useState([]);

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        // const response = await axios.get('http://localhost:3001/api/music', {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/music`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        console.log(response);
        setMusicList(response.data.music);
      } catch (error) {
        console.error('Error fetching music:', error);
      }
    };

    fetchMusic();
  }, []);

  useEffect(() => {
    console.log(musicList);
  }, [musicList]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <main className="flex-grow px-4 flex flex-col items-center justify-center text-center w-full">
        <div className="my-8">
          <div className="border-t-4 border-b-4 border-white pt-4 pb-4">
            <h1 className="text-4xl sm:text-6xl font-bold text-white inline-block mx-auto">Give us a listen!</h1>
          </div>
        </div>

        <ul className="w-full">
          {musicList && musicList.map((music) => (
            <li key={music._id} className="mb-4 pb-2 flex flex-col items-center">
              <div className="border-b border-white w-full sm:max-w-7xl">
                <div className="hidden sm:grid grid-cols-3 gap-4 text-xl text-white">
                <div>{music.location}</div>
                  <div>{music.name}</div>
                  <div>{music.artist}</div>              
                  <div className="col-span-3 flex justify-center mb-4">
                    <audio controls>
                      <source src={music.mp3FilePath} type="audio/mpeg" />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                </div>
                <div className="sm:hidden text-white text-center">
                  <div className="font-bold">{music.location}</div>
                  <div className="font-bold">{music.name}</div>
                  <div className="font-bold">{music.artist}</div>
                  <div className="flex justify-center mt-2 mb-2">
                    <audio controls>
                      <source src={music.mp3FilePath} type="audio/mpeg" />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="py-4 w-full sm:max-w-4xl">
          <img src={tim3} alt="Tim" className="mx-auto max-w-full h-auto" />
        </div>
      </main>
      <div className="w-full mx-auto md:pt-14 pt-8">
        <Footer />
      </div>
    </div>
  );
}

export default Music;