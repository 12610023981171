import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function AdminLink() {
  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const handleLoginLogoutClick = () => {
    if (authToken) {
      logout();
      navigate('/login');
    } else {
      navigate('/login');
    }
  };

  return (
    <button onClick={handleLoginLogoutClick} className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-lg">
      <FontAwesomeIcon icon={authToken ? faUnlock : faLock} size="lg" />
    </button>
  );
}

export default AdminLink;


