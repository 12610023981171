import adrian1 from './images/adrian1.jpg';
import sadith1 from './images/sadith1.jpg';
import tim1 from './images/tim1.jpg';
import Footer from './Footer';

function AboutUs() {
  return (
    <div className="font-mono"
    >
      <div className="flex flex-col md:flex-row items-center justify-center px-4">
        <p className="order-2 md:order-none text-white text-sm sm:text-xl md:w-1/2 mx-8">Adrian started his first band 'You Jump First' in Cambridge, UK, in 1997.  The band played
          their own songs in local pubs and colleges for 13 years and performed in London, Brighton, New York
          and Montreal.  In 2010, Adrian moved to Canada and formed 'Untidy Naked Dilemma', a 7-piece band,
          mainly playing guitar and synth hits from the 80s and 90s.  In 2007, 'Untidy Naked Dilemma' went 
          their separate ways, but with the recent move of seasoned drummer and old friend Tim Bussey from 
          Cambridge to London, Ontario, BAM! emerged, playing guitar-based rock & roll from the 60s to the present day.
        </p>
        <img src={adrian1} alt="Adrian" className="order-1 md:order-none w-full md:w-1/2 h-auto object-cover my-8" />
      </div>

      <div className="flex flex-col md:flex-row-reverse items-center justify-center px-4">
      <p className="order-2 md:order-none text-white text-sm sm:text-xl md:w-1/2 mx-8">Sid completed his Bachelor's degree in Music in 2018 at Dalhousie University, specializing in solo acoustic and
          classical guitar performance.  Before this, he had years of experience playing trumpet in various jazz ensembles, 
          as well as being a tenor/baritone singer in a variety of academic and professional groups including the Dalhousie
          Chorus and Xara Choral Theatre.  Briefly in his undergrad, he was also the guitarist for "Nightcap Quintet", a five-piece
          jazz group that featured saxophone, drums, bass, and piano, playing a medley of old jazz standards.  Sid has been proud to
          be a part of several live-performance initiatives in the Maritimes, playing guitar for "The Big Sing," a monthly community
          "singalong" group, and also composing, recording, and producing music for live theatre at the Halifax Fringe Festival.  Now
          a PhD Neuroscience candidate, Sid joined BAM! in 2023, making his debut as a "rock n' roll" guitarist and background
          vocalist.
        </p>
        <img src={sadith1} alt="Sadith" className="order-1 md:order-none w-full md:w-1/2 h-auto object-cover my-8" />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center px-4">
        <p className="order-2 md:order-none text-white text-sm sm:text-xl md:w-1/2 mx-8">Tim moved from Vancouver Island to Vancouver in 1985 to join the band Rubber Biscuit (later Big Medicine),
          replacing their drummer who had joined Bryan Adams' band.  Shortly after, Men At Work's producer relocated
          from Australia to Vancouver to manage the band.  In November 1986, Tim and other members of Big Medicine
          joined with the lead singer of The Villains and a crack horn section to form SkaBoom!, who soon became one of
          Canada's legendary Ska bands, sharing a stage with the likes of Red Hot Chili Peppers and Fishbone, and eventually
          broke the record for the most appearances at the legendary Commodore Ballroom.  In the final years of Skaboom!,
          a drummer was hired and Tim fronted the band.  Tim moved to England in 1992 and had regional radio success singing his
          own songs recorded with Canadian power-pop legends The Odds, and produced by Keith Wilkinson of Squeeze.  His band Violet
          Transmissions released an album (available on Spotify) including some of those songs plus a cover of Thomas Dolby's "She
          Blinded Me With Science" to support Women in Science, the video for which involved cast members from the Canadian Sci-fi
          classic Orphan Black.  Before moving back to Canada, Tim formed Pavlov's Dogz, a band consisting of neuroscientists who play
          alongside conferences in cities as diverse as Cambridge England, Boston, Washington DC and New York.  Once back in Canada, Tim
          soon joined BAM! in 2017 as drummer and backing vocalist.
        </p>
        <img src={tim1} alt="Tim" className="order-1 md:order-none w-auto h-[90vh] max-w-full object-cover my-9" />
      </div>
      <div className="pt-4">
      <Footer />
      </div>
    </div>
  );
};

export default AboutUs;